<template>
  <content-items-view
    content-type="events"
    content-type-singular="event"
    content-name="Events"
    content-name-singular="Events"
  />
</template>

<script>
import ContentItemsView from '@/views/content-item/content-items-view/ContentItemsView.vue'

export default {
  components: {
    ContentItemsView,
  },
}
</script>

<style scoped>

</style>
